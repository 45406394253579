import React from 'react';
import NeuroRoomJPG from '@assets/images/neuro-room.jpg';
import NeuroRoomMobileJPG from '@assets/images/neuro-room-mobile.jpg';
import JobsMain from '~/components/organisms/JobsMain/JobsMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const JobsPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={NeuroRoomJPG}
        mobileHero={NeuroRoomMobileJPG}
        altHero="Neurologie Praxisraum"
      >
        <JobsMain />
      </HeaderFooterLayout>
    </>
  );
};

export default JobsPage;
