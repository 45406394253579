import React from 'react';
import styled from '@emotion/styled';
import { Container, ContainerSmall } from '~/utils/styles/utils';

const JobsMain: React.FC = () => {
  return (
    <>
      <Container>
        <ContainerSmall>
          <ContainerSmallOverwritten>
            <h1>Stellenanzeigen</h1>
            <p>
              Wir sind ständig auf der Suche nach motivierten und qualifizierten
              MTAs, Krankenschwestern/pfleger oder Praxisassistenten/-innen (m/w
              in Teilzeit) für unser junges und freundliches Team.{' '}
            </p>
            <p>
              Ihr Profil: Ausbildung zu einem der o.g. Berufe, Erfahrung in der
              Arztpraxis (vorzugsweise in der neurologischen Arztpraxis), gerne
              mit Erfahrung in Neurographie, EEG oder SEPs, freundliches
              Auftreten, Teamfähigkeit.
            </p>
            <p>
              Bei Interesse senden Sie gerne Ihre aussagekräftigen
              Bewerbungsunterlagen an:{' '}
              <a href="mailto:uez@neurozentrum-arabellapark.de">
                uez@neurozentrum-arabellapark.de
              </a>
            </p>
            <br />
            <p>
              Aktuell suchen wir eine/n Ergotherapeuten/in in TZ/Minjobbasis
              (5-10h/Woche, mit Aussicht auf Erhöhung) mit Erfahrung und
              Schwerpunkt mit neurologischen Patienten.{' '}
            </p>
            <p>
              Ihre Aufgaben: Eigenverantwortliche Durchführung von
              neurologischer Ergotherapie mit unseren Patienten, Durchführung
              von Neuro-/Biofeedback bei unseren Patienten (auch gerne nach
              Anlernen).
            </p>
            <p>
              Bei Interesse senden Sie gerne Ihre aussagekräftigen
              Bewerbungsunterlagen an:{' '}
              <a href="mailto:uez@neurozentrum-arabellapark.de">
                uez@neurozentrum-arabellapark.de
              </a>
            </p>
            <p>
              Wir suchen immer wieder (Teil-) untermiete für unsere Räume. Falls
              Sie fachlich zu uns passen oder uns durch einen bestimmten
              Schwerpunkt sinnvoll ergänzen können, melden Sie sich gerne bei
              uns.
            </p>
            <br />
            <p>
              Aktuell suchen wir einen psychologischen oder ärztlichen
              Psychotherapeutin/en für dienstags und freitags für
              privatärztliche/therapeutische Psychotherapie oder einen
              Mediator/Coach (m/w).{' '}
            </p>
            <p>
              Bei Interesse gerne melden unter{' '}
              <a href="mailto:roever@neurozentrum-arabellapark.de">
                roever@neurozentrum-arabellapark.de
              </a>
            </p>
          </ContainerSmallOverwritten>
        </ContainerSmall>
      </Container>
    </>
  );
};

const ContainerSmallOverwritten = styled.div`
  p {
    font-size: var(--primary-font-size);
    line-height: 1.5;
  }
`;

export default JobsMain;
