import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout } from '~/components/molecules/Layout';
import { SEO } from '~/components/atoms/SEO';
import JobsPage from './JobsPage/JobsPage';
import { TranslationContext } from '~/utils/styles/utils';

const Stellenanzeigen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title="Neurozentrum Arabellapark - Stellenanzeigen"
        metaDescription="Neurozentrum Arabellapark - Stellenanzeigen"
      />
      <TranslationContext.Provider value={t}>
        <JobsPage />
      </TranslationContext.Provider>
    </Layout>
  );
};

export const useTranslationContext = () => React.useContext(TranslationContext);

export default Stellenanzeigen;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["jobspage"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
